import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from "react-responsive";

import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import iconLogo from './../../asset/images/logo_only-brightly.svg';
import iconGCgcn from './../../asset/images/atoms-medical-svgrepo-com.svg';
import iconThermoEngine from './../../asset/images/3815451.png';
import iconGcnVS from './../../asset/images/molecule-svgrepo-com.svg';

const Home = () => {
  const isMobile = useMediaQuery({
    query : "(max-width: 859px)"
  });

  const customRenderThumbs = () => {
    const thumbList = [
      (
        <div key="carousel-thumb-first" className="thumb-section">
        <div className="thumb-article">
          ChemicAI
        </div>
        <div className="thumb-logo">
          <img src={iconLogo} alt="ChemicAI Logo" />
        </div>
        </div>
      ),
      (
        <div key="carousel-thumb-second" className="thumb-section">
          <div className="thumb-article">
            GC-gcn
          </div>
          <div className="thumb-logo">
            <img src={iconGCgcn} alt="GC-gcn Logo" />
          </div>
        </div>
      ),
      (
        <div key="carousel-thumb-third" className="thumb-section">
        <div className="thumb-article">
          {isMobile ? <>Thermo<br />Engine</> : "ThermoEngine" }
        </div>
        <div className="thumb-logo">
          <img src={iconThermoEngine} alt="ThermoEngine Logo" />
        </div>
        </div>
      ),
      (
        <div key="carousel-thumb-fourth" className="thumb-section">
        <div className="thumb-article">
          gcn-VS
        </div>
        <div className="thumb-logo">
          <img src={iconGcnVS} alt="gcn-VS Logo" />
        </div>
        </div>
      )
    ]
    return thumbList;
  }

  return (
    <div className="main-home">
      {/* */}
      <Carousel renderThumbs={customRenderThumbs} autoPlay={true} interval={3000} infiniteLoop={true} thumbWidth="calc(100% / 4)">
          <div className="section">
            <div className="article">
              <div className="description">
                <h1>ChemicAI</h1>
                <h2>화학에 <span>혁신</span>을 더하다</h2>
                <p>
                  딥러닝 기술이 접목한 혁신적이고 정확한 해결 솔루션들을 제공합니다.<br />
                  당신의 연구에 혁신을 적용하세요!
                </p>
              </div>
              <div className="image">
                <img src={iconLogo} alt="ChemicAI Logo" />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="article">
              <div className="description">
                <h1>GC-gcn</h1>
                <h2>ChemicAI가 제공하는 <span>혁신</span></h2>
                <p>
                  딥러닝 모델을 이용한 혁신적인 물성 예측 알고리즘.<br />
                  보다 정확하고 효율적인 설계가 가능해집니다.
                </p>
                <Link className="link" to="/products#GCgcn">
                  Explore <span className="oxanium">GC-gcn</span>
                </Link>
              </div>
              <div className="image">
                <img src={iconGCgcn} alt="GC-gcn Logo" />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="article">
              <div className="description">
                <h1>ThermoEngine</h1>
                <h2>ChemicAI가 제공하는 <span>계산</span></h2>
                <p>
                  복잡한 열역학 계산을 쉽고 빠르게 제공합니다.<br />
                  자신의 실험 결과와 이론적인 결과 차이를 한눈에 비교할 수 있습니다.
                </p>
                <Link className="link" to="/products#ThermoEngine">
                  Explore <span className="oxanium">ThermoEngine</span>
                </Link>
              </div>
              <div className="image">
                <img src={iconThermoEngine} alt="ThermoEngine Logo" />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="article">
              <div className="description">
                <h1>gcn-VS</h1>
                <h2>ChemicAI가 제공하는 <span>편의</span></h2>
                <p>
                  수만개의 분자 구조를 학습한 인공지능이<br />
                  찾고 계신 물질과 비슷한 물질을 빠르게 탐색해줍니다.
                </p>
                <Link className="link" to="/products#gcnVS">
                  Explore <span className="oxanium">gcn-VS</span>
                </Link>
              </div>
              <div className="image">
                <img src={iconGcnVS} alt="gcn-VS Logo" />
              </div>
            </div>
          </div>
      </Carousel>
    </div>
  );
};

export default Home;