import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import './Products.css';

import productsIntroVideo from './../../asset/videos/products_intro_video.mp4';
import productsIntroVideoPoster from './../../asset/images/products_intro_video_poster.png';

import iconGCgcn from './../../asset/images/atoms-medical-svgrepo-com.svg';
import iconThermoEngine from './../../asset/images/3815451.png';
import iconSeoNew from './../../asset/images/molecule-svgrepo-com.svg';
import imgGCGcn from './../../asset/images/GC-gcn Online.png';

const Products = () => {
  const { hash } = useLocation();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -64; 
    document.querySelector('.app-main').scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }
  
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      scrollWithOffset(element);
    }
  }, [hash])

  return (
    <div className="main-products">
      <div className="intro">
        <video autoPlay loop muted playsInline poster={productsIntroVideoPoster}>
          <source src={productsIntroVideo} type="video/mp4" />
        </video>
        <div className="contents">
          <HashLink smooth to="#GCgcn" scroll={el => scrollWithOffset(el)}>
            <div className="item">
              <div className="picture">
                <img src={iconGCgcn} alt="GC-gcn Icon" />
              </div>
              <div className="name">GC-gcn</div>
            </div>
          </HashLink>
          <HashLink smooth to="#ThermoEngine" scroll={el => scrollWithOffset(el)}>
            <div className="item">
              <div className="picture">
                <img src={iconThermoEngine} alt="ThermoEngine Icon" />
              </div>
              <div className="name">ThermoEngine</div>
            </div>
          </HashLink>
          <HashLink smooth to="#gcnVS" scroll={el => scrollWithOffset(el)}>
            <div className="item">
              <div className="picture">
                <img src={iconSeoNew} alt="gcn-VS Icon" />
              </div>
              <div className="name">gcn-VS</div>
            </div>
          </HashLink>
        </div>
      </div>
      <div className="product" id="GCgcn">
        <div className="title">
          <h1>GC-gcn</h1>
        </div>
        <div className="body">
          <h2>기존에 불가능했던 빠르고 정확한 물성 예측</h2>
          <div className="titleimage">
            <img src={imgGCGcn} alt="GC-gcn Sample" />
          </div>
          <div className="links">
            <Link className="button learn-more" to="/products/gcgcn/more">LEARN MORE</Link>
            <Link className="button online-demo" to="/products/gcgcn/online">ONLINE DEMO</Link>
            <Link className="button download" to="/contact">DOWNLOAD</Link>
          </div>
        </div>
      </div>
      <div className="product dark" id="ThermoEngine">
        <div className="title">
          <h1>ThermoEngine</h1>
        </div>
      </div>
      <div className="product" id="gcnVS">
        <div className="title">
          <h1>gcn-VS</h1>
        </div>
      </div>
    </div>
  );
};

export default Products;