import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from 'react-burger-menu';

import './Header.css';

import logo from './../asset/images/logo_long.svg'

const Header = () => {
  const isMobile = useMediaQuery({
      query : "(max-width: 859px)"
  });

  const MenuPC = () => {
    return (
      <div className="header-links">
        <NavLink to="/">
            <div className="link">HOME</div>
        </NavLink>
        <NavLink to="/about">
            <div className="link">ABOUT US</div>
        </NavLink>
        <NavLink to="/products">
            <div className="link">PRODUCTS</div>
        </NavLink>
        <NavLink to="/contact">
            <div className="link">CONTACT US</div>
        </NavLink>
      </div>
    )
  }

  const closeMenu = () => {
    Menu.setState({areMenusOpen: false});
  }

  const MenuMobile = () => {
    return (
      <div className="header-links" width={ "50px" }>
        <Menu right>
          <NavLink to="/" onClick={closeMenu}>
              <div className="link">HOME</div>
          </NavLink>
          <NavLink to="/about" onClick={closeMenu}>
              <div className="link">ABOUT US</div>
          </NavLink>
          <NavLink to="/products" onClick={closeMenu}>
              <div className="link">PRODUCTS</div>
          </NavLink>
          <NavLink to="/contact" onClick={closeMenu}>
              <div className="link">CONTACT US</div>
          </NavLink>
        </Menu>
      </div>
    )
  }

  return (
    <header className="app-header">
        <Link to="/">
            <div className="header-logo">
                <img src={logo} alt="ChemicAI Logo" />
            </div>
        </Link>
        { isMobile ? MenuMobile() : MenuPC() }
    </header>
  );
};

export default Header;