import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './Main.css';

import Home from './home/Home'
import About from './about/About'
import Products from './products/Products'
import ProductDesc from './products/ProductsDesc'
import Contact from './contact/Contact'

const Main = () => {
  return (
    <div className="app-main">
        <Routes>
            <Route
                path="/"
                element={<Home />}
            />
            <Route
                path="/about"
                element={<About />}
            />
            <Route
                path="/products"
                element={<Products />}
            />
            <Route
                path="/products/:product/:page"
                element={<ProductDesc />}
            />
            <Route
                path="/contact"
                element={<Contact />}
            />
        </Routes>
    </div>
  );
};

export default Main;