import React from 'react';
import { useMediaQuery } from "react-responsive";

import './Property.css';

const Property = ({ smiles, data, svg }) => {
  const isMobile = useMediaQuery({
      query : "(max-width: 859px)"
  });

  return (
    <div className="products-gcgcn-online-property">
      {
            isMobile ?
            (
              <img className="imageSVG" src={svg} />
            ) : <></>
      }
      <table>
        <tbody>
          <tr>
            {
              isMobile ? <></> : 
              (
                <td rowSpan={8} className="image-body">
                  <img src={svg} />
                </td>
              )
            }
            <th rowSpan={2}>TBN</th>
            <td className="value">{ Math.round(data.tbn?.value * 1000) / 1000 } K</td>
            <th rowSpan={2}>TMN</th>
            <td className="value">{ Math.round(data.tmn?.value * 1000) / 1000 } K</td>
          </tr>
          <tr>
            <td className="uncertainty">±{ Math.round(data.tbn?.uncertainty * 1000) / 1000 } K</td>
            <td className="uncertainty">±{ Math.round(data.tmn?.uncertainty * 1000) / 1000 } K</td>
          </tr>
          <tr>
            <th rowSpan={2}>TC</th>
            <td className="value">{ Math.round(data.tc?.value * 1000) / 1000 } K</td>
            <th rowSpan={2}>PC</th>
            <td className="value">{ Math.round(data.pc?.value * 1000) / 1000 } kPa</td>
          </tr>
          <tr>
            <td className="uncertainty">±{ Math.round(data.tc?.uncertainty * 1000) / 1000 } K</td>
            <td className="uncertainty">±{ Math.round(data.pc?.uncertainty * 1000) / 1000 } kPa</td>
          </tr>
          <tr>
            <th rowSpan={2}>VC</th>
            <td className="value">{ Math.round(data.vc?.value * 1000000) / 1000 } ml/mol</td>
            <th rowSpan={2}>TF</th>
            <td className="value">{ Math.round(data.tf?.value * 1000) / 1000 } K</td>
          </tr>
          <tr>
            <td className="uncertainty">±{ Math.round(data.vc?.uncertainty * 1000000) / 1000 } ml/mol</td>
            <td className="uncertainty">±{ Math.round(data.tf?.uncertainty * 1000) / 1000 } K</td>
          </tr>
          <tr>
            <th rowSpan={2}>HFUS</th>
            <td className="value">{ Math.round(data.hfus?.value * 1000) / 1000 } kJ/mol</td>
            <th rowSpan={2}>HFORM</th>
            <td className="value">{ Math.round(data.hform?.value * 1000) / 1000 } kJ/mol</td>
          </tr>
          <tr>
            <td className="uncertainty">±{ Math.round(data.hfus?.uncertainty * 1000) / 1000 } kJ/mol</td>
            <td className="uncertainty">±{ Math.round(data.hform?.uncertainty * 1000) / 1000 } kJ/mol</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Property;