import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="main-contact">
        <h1><a href="mailto:neros12@naver.com">neros12@naver.com</a></h1>
    </div>
  );
};

export default Contact;