import React from 'react';

import './GCGcn.css';

import MoreGCGcn from './more/MoreGCGcn';
import OnlineGCGcn from './online/OnlineGCGcn';

const GCGcn = ({ page }) => {
  const getPage = () => {
    if (page === "more") {
      return <MoreGCGcn />
    } else if (page === "online") {
      return <OnlineGCGcn />
    }
  }

  return (
    <div className="products-gcgcn">
        { getPage() }
    </div>
  );
};

export default GCGcn;