import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

import './About.css';

import avatarHwang from './../../asset/images/avatar_hwang.png';
import avatarBang from './../../asset/images/avatar_bang.png';
import avatarUnknown from './../../asset/images/avatar_unknown.png';

const About = () => {
  const isMobile = useMediaQuery({
    query : "(max-width: 859px)"
  });

  return (
    <div className="main-about">
      <div className="intro">
        <div>
          <h1>ChemicAI</h1>
          <p>
            <span className="oxanium">ChemicAI</span>는 화학(Chemical)과 인공지능(AI)을 결합하여,{ isMobile ? " " : <br /> }
            다양한 화학공학 분야에 딥러닝 기술을 적용해 서비스를 하는 회사입니다.
          </p>
          <p>
            인공지능을 사용한 정확하고 빠른 예측을 통해{ isMobile ? " " : <br /> }
            실제 실험 결과와 가장 유사한 시뮬레이션을 제공합니다.
          </p>
          <p>
            우리 회사는 실험적인 과정 없이 기술 개발이 가능한 세상 구축이라는{ isMobile ? " " : <br /> }
            궁극적인 비전을 추진하고 있습니다.
          </p>
        </div>
      </div>
      <div className="members">
        <div>
          <h1>우리는</h1>
          <div className="profile">
            <div className="img">
              <img src={avatarHwang} alt="Hwang's Avater" />
            </div>
            <div className="description">
              <div className="name">황선유</div>
              <div className="responsibility">딥러닝 엔진 개발</div>
              <ul className="career">
                <li>고려대학교 공과대학 화공생명공학과 학사</li>
                <li>고려대학교 공과대학 화공생명공학과 석사(수료)</li>
              </ul>
            </div>
          </div>
          <div className="profile">
            <div className="img">
              <img src={avatarBang} alt="Bang's Avater" />
            </div>
            <div className="description">
              <div className="name">방현웅</div>
              <div className="responsibility">소프트웨어 개발</div>
              <ul className="career">
                <li>고려대학교 정보대학 컴퓨터학과 학사</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="profile sub">
            <div className="img">
              <img src={avatarUnknown} alt="Unknown Avater" />
            </div>
            <div className="description">
              <div className="name">이하연</div>
              <div className="responsibility">딥러닝 엔진 관리</div>
              <ul className="career">
                <li>UC DAVIS Chemical engineering 학사</li>
              </ul>
            </div>
          </div>
          <div className="profile sub">
            <div className="img">
              <img src={avatarUnknown} alt="Unknown Avater" />
            </div>
            <div className="description">
              <div className="name">이주학</div>
              <div className="responsibility">그래픽 디자이너</div>
              <ul className="career">
                <li>숭실대학교 IT대학 컴퓨터학부 학사</li>
              </ul>
            </div>
          </div>
          <h2>...And you!</h2>
          <Link to="/contact" className="link">With Us!</Link>
        </div>
      </div>
    </div>
  );
};

export default About;