import React from 'react';

import './GcnVS.css';

const GcnVS = () => {
  return (
    <div className="products-gcnvs">

    </div>
  );
};

export default GcnVS;