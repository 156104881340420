import React from 'react';
import { useParams } from 'react-router-dom';

import './ProductsDesc.css';

import GCGcn from './GCgcn/GCGcn';
import ThermoEngine from './ThermoEngine/ThermoEngine';
import GcnVS from './GcnVS/GcnVS';

const ProductsDesc = () => {
  const { product, page } = useParams();

  const getPage = () => {
    if (product === "gcgcn") {
      return <GCGcn page={page} />
    } else if (product === "thermoengine") {
      return <ThermoEngine page={page} />
    } else if (product === "gcnvs") {
      return <GcnVS page={page} />
    }
  }

  return (
    <div className="main-products-desc">
        { getPage() }
    </div>
  );
};

export default ProductsDesc;