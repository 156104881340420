import React from 'react';

import './MoreGCGcn.css';

const MoreGCGcn = () => {
  return (
    <div className="products-gcgcn-online">
        TEST1
    </div>
  );
};

export default MoreGCGcn;