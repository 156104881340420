import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './OnlineGCGcn.css';

import Property from './Property';

import svgUnknown from './../../../../asset/images/question-mark-sign-svgrepo-com.svg';
import svgSearch from './../../../../asset/images/search_icon.svg';

const OnlineGCGcn = () => {
  const dataInit = {
    tbn: { value: 0, uncertainty: 0 },
    tmn: { value: 0, uncertainty: 0 },
    tc: { value: 0, uncertainty: 0 },
    pc: { value: 0, uncertainty: 0 },
    vc: { value: 0, uncertainty: 0 },
    tf: { value: 0, uncertainty: 0 },
    hfus: { value: 0, uncertainty: 0 },
    hform: { value: 0, uncertainty: 0 }
  };

  const [smiles, setSmiles] = useState('');
  const [data, setData] = useState(dataInit);
  const [timer, setTimer] = useState(0);
  const [svg, setSvg] = useState(svgUnknown);
  
  const handleSmiles = (e) => {
    setSmiles(e.target.value)
  }

  const getResult = () => {
    axios.get(`https://api.chemicai.org/SMILES/${smiles}`)
      .then(({ data }) => {
        if (!data.success) {
          throw new Error('올바른 SMILES 표현법이 아니거나, 지원하지 않는 물질입니다.');
        }

        const result = data.result;

        const source = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(result.svg);

        setData(result.property);
        setSvg(source);
      })
      .catch((err) => {
        setData(dataInit);
        setSvg(svgUnknown);
      })
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      if (smiles) {
        getResult()
      }
    }, 800);
    setTimer(newTimer);
  }, [smiles])

  return (
    <div className="products-gcgcn-online">
        <h1><span className="oxanium">GC-gcn</span> Online Demo</h1>
        <div className="search">
          <input type="text" value={smiles} onChange={handleSmiles} placeholder="Enter SMILES here (ex. CCO)" />
          <label class="lens">
            <img src={svgSearch} />
          </label>
        </div>
        <div className="property">
          <h2>Result:</h2>
          <Property smiles={smiles} data={data} svg={svg} />
        </div>
    </div>
  );
};

export default OnlineGCGcn;