import './App.css';
import { BrowserRouter } from 'react-router-dom';

import Header from './routes/Header';
import Main from './routes/Main';
import Footer from './routes/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
